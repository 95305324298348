.engineering {
  height: 912px;
  background-image: url("../../../Assets/Images/CarbonFiberPage/HybridSystemsCarbon_bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-end;
  padding: 100px 0;
}
.engineering_wrap {
  display: flex;
  flex-direction: column;
  z-index: 0;
}

.title {
  color: #FFF;
  font-family: Gilroy, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 40px;
}

.desc {
  color: #FFF;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 40px;
}

.button {
  color: var(--colors-base-primary-foreground, #FFF);
  font-family: Gilroy, sans-serif;
  text-decoration: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  width: 240px;
  height: 42px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid var(--Colors-Border-border-primary, #D0D5DD);
  position: relative;
  overflow: hidden;
  z-index: 0;
  background-color: transparent;
  transition: color 0.5s ease;
}
.button::before {
  content: "";
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: -1;
  transition: bottom 0.5s ease-in-out;
}
.button:hover {
  color: var(--colors-base-primary-foreground, #000);
}
.button:hover::before {
  bottom: 0;
}

@media (max-width: 720px) {
  .engineering {
    height: 666px;
    padding: 40px 0;
    background-image: url("../../../Assets/Images/CarbonFiberPage/HybridSystemsCarbon_bg_tel.webp");
  }
  .title {
    font-size: 28px;
  }
  .button {
    width: 175px;
    height: 42px;
    padding: 12px 12px;
  }
}

